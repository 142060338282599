@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-DisplayMedium.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter Bold';
  src: url('./fonts/Inter-DisplaySemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Signature';
  src: url('./fonts/Signature--Caveat.woff2') format('woff2');
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-variation-settings: 'wght' 500, 'slnt' 0;
  font-synthesis: none;
  vertical-align: baseline;
  line-height: 1;
  box-sizing: border-box;
  outline-offset: 2px;
  letter-spacing: 0.3px;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-feature-settings: "calt" off, "cv02" on;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

@media only print {
  /* @page {
    size: 210mm 297mm;
    margin: 10mm;
  } */
  @page {
    size: 80mm 297mm;
    margin: 5mm;
  }
  main > div:nth-child(1) {
    padding: 0 !important;
  }
  body {
    background-color: white !important;
    background-image: none !important;
  }
  .print-hide {
    display: none !important;
  }
  * {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .component--box {
    padding: 0 !important;
    width: 100% !important;
    background-color: unset;
  }
}

body, input, textarea, select, button {
  color: #1A1F35;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

strong, strong > span, strong > span > span, .component--banner p, .make-me-strong {
  font-family: 'Inter Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
strong > code, a > code {
  font-weight: bold;
}
code {
  white-space: pre-wrap;
  word-break: break-all;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid #f0f1f3;
}

ul li, ol li {
  line-height: 1.1rem;
}

ul.plain, ol.plain {
  padding-left: 1.5rem;
}
ul.plain li a, ol.plain li a {
  color: inherit;
}
ul.plain li + li, ol.plain li + li {
  margin-top: 0.5rem;
}
ul.plain li ul li:first-child {
  margin-top: 0.5rem;
}

.limit-width-1 {
  max-width: 22.5rem;
}
.limit-width-2 {
  max-width: 40rem;
}

.grecaptcha-badge {
  display: none;
}
